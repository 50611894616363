[data-rs-theme='fionaAccent'][data-rs-color-mode='light'],
[data-rs-theme='fionaAccent'][data-rs-color-mode='dark'] {
	--rs-color-on-background-primary: #fff;
}

[data-rs-theme='fionaAccent'][data-rs-color-mode='light'] {
	--rs-color-background-page: #ffffff;
	--rs-color-foreground-primary: #ff7200;
	--rs-color-background-primary: #ff7200;
	--rs-color-rgb-background-page: 255, 255, 255;
	--rs-color-rgb-background-primary: 255, 114, 0;
}

[data-rs-theme='fionaAccent'][data-rs-color-mode='dark'] {
	--rs-color-background-page: #000000;
	--rs-color-foreground-primary: #ff7200;
	--rs-color-background-primary: #ff7200;
	--rs-color-rgb-background-page: 0, 0, 0;
	--rs-color-rgb-background-primary: 255, 114, 0;
}
