[data-rs-theme='fionaBrand'][data-rs-color-mode='light'],
[data-rs-theme='fionaBrand'][data-rs-color-mode='dark'] {
}

[data-rs-theme='fionaBrand'][data-rs-color-mode='light'] {
	--rs-color-foreground-primary: #14e075;
}

[data-rs-theme='fionaBrand'][data-rs-color-mode='dark'] {
	--rs-color-foreground-primary: #14e075;
}
