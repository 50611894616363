[data-rs-theme='fiona'][data-rs-color-mode='light'],
[data-rs-theme='fiona'][data-rs-color-mode='dark'] {
	--rs-font-family-title: Nunito;
	--rs-font-family-body: Nunito;
	--rs-font-weight-regular: 400;
	--rs-font-weight-medium: 500;
	--rs-font-weight-bold: 700;
	--rs-font-weight-heavy: 800;
	--rs-font-weight-black: 900;
	--rs-font-size-title-1: 40px;
	--rs-line-height-title-1: 52.8px;
	--rs-font-weight-title-1: var(--rs-font-weight-bold);
	--rs-font-size-title-2: 32px;
	--rs-line-height-title-2: 42.24px;
	--rs-font-weight-title-2: var(--rs-font-weight-bold);
	--rs-font-size-title-3: 30px;
	--rs-line-height-title-3: 39.6px;
	--rs-font-weight-title-3: var(--rs-font-weight-bold);
	--rs-font-size-title-4: 24px;
	--rs-line-height-title-4: 31.68px;
	--rs-font-weight-title-4: var(--rs-font-weight-bold);
	--rs-font-size-title-5: 22px;
	--rs-line-height-title-5: 29.04px;
	--rs-font-weight-title-5: var(--rs-font-weight-bold);
	--rs-font-size-title-6: 20px;
	--rs-line-height-title-6: 26.4px;
	--rs-font-weight-title-6: var(--rs-font-weight-bold);
	--rs-font-size-featured-1: 32px;
	--rs-line-height-featured-1: 42.24px;
	--rs-font-weight-featured-1: var(--rs-font-weight-regular);
	--rs-font-size-featured-2: 24px;
	--rs-line-height-featured-2: 31.68px;
	--rs-font-weight-featured-2: var(--rs-font-weight-regular);
	--rs-font-size-featured-3: 20px;
	--rs-line-height-featured-3: 26.4px;
	--rs-font-weight-featured-3: var(--rs-font-weight-regular);
	--rs-font-size-body-1: 18px;
	--rs-line-height-body-1: 26.64px;
	--rs-font-family-body-1: var(--rs-font-family-body);
	--rs-font-weight-body-1: var(--rs-font-weight-regular);
	--rs-font-size-body-2: 16px;
	--rs-line-height-body-2: 23.68px;
	--rs-font-family-body-2: var(--rs-font-family-body);
	--rs-font-weight-body-2: var(--rs-font-weight-regular);
	--rs-font-size-body-3: 14px;
	--rs-line-height-body-3: 20.72px;
	--rs-font-family-body-3: var(--rs-font-family-body);
	--rs-font-weight-body-3: var(--rs-font-weight-regular);
	--rs-font-size-caption-1: 12px;
	--rs-line-height-caption-1: 12px;
	--rs-font-family-caption-1: var(--rs-font-family-body);
	--rs-font-weight-caption-1: var(--rs-font-weight-regular);
	--rs-font-size-caption-2: 10px;
	--rs-line-height-caption-2: 10px;
	--rs-font-family-caption-2: var(--rs-font-family-body);
	--rs-font-weight-caption-2: var(--rs-font-weight-bold);
	--rs-unit-base: 4px;
	--rs-unit-radius-small: 4px;
	--rs-unit-radius-medium: 8px;
	--rs-unit-radius-large: 16px;
	--rs-unit-x1: 4px;
	--rs-unit-x2: 8px;
	--rs-unit-x3: 12px;
	--rs-unit-x4: 16px;
	--rs-unit-x5: 20px;
	--rs-unit-x6: 24px;
	--rs-unit-x7: 28px;
	--rs-unit-x8: 32px;
	--rs-unit-x9: 36px;
	--rs-unit-x10: 40px;
	--rs-duration-fast: 200ms;
	--rs-duration-medium: 300ms;
	--rs-duration-slow: 400ms;
	--rs-easing-standard: cubic-bezier(0.4, 0, 0.2, 1);
	--rs-easing-accelerate: cubic-bezier(0.4, 0, 1, 1);
	--rs-easing-decelerate: cubic-bezier(0, 0, 0.2, 1);
	--rs-shadow-raised: 0px 0px 24px rgba(50, 50, 50, 0.16);
	--rs-shadow-overlay: 0px 0px 24px rgba(50, 50, 50, 0.16);
}

[data-rs-theme='fiona'][data-rs-color-mode='light'] {
	--rs-color-foreground-neutral: #323232;
	--rs-color-foreground-neutral-faded: #757575;
	--rs-color-foreground-disabled: #d6d6d6;
	--rs-color-foreground-primary: #592b86;
	--rs-color-foreground-positive: #30a46c;
	--rs-color-foreground-critical: #f03d3d;
	--rs-color-background-neutral: #e0e0e0;
	--rs-color-background-neutral-faded: #f9f9f9;
	--rs-color-background-neutral-highlighted: #cacaca;
	--rs-color-background-disabled: #f9f9f9;
	--rs-color-background-disabled-faded: #fafafa;
	--rs-color-background-primary: #592b86;
	--rs-color-background-primary-highlighted: #8957b6;
	--rs-color-background-primary-faded: #f8f2fe;
	--rs-color-background-positive: #30a46c;
	--rs-color-background-positive-faded: #d6ede2;
	--rs-color-background-positive-highlighted: #59b689;
	--rs-color-background-critical: #f03d3d;
	--rs-color-background-critical-faded: #fcd8d8;
	--rs-color-background-critical-highlighted: #ff7468;
	--rs-color-border-neutral: #9e9e9e;
	--rs-color-border-neutral-faded: #e0e0e0;
	--rs-color-border-disabled: #ececec;
	--rs-color-border-primary: #592b86;
	--rs-color-border-primary-faded: #ded5e7;
	--rs-color-border-positive: #30a46c;
	--rs-color-border-positive-faded: #d6ede2;
	--rs-color-border-critical: #f03d3d;
	--rs-color-border-critical-faded: #fcd8d8;
	--rs-color-background-page: #ffffff;
	--rs-color-background-page-faded: #f4f4f4;
	--rs-color-background-elevation-base: #ffffff;
	--rs-color-background-elevation-raised: #ffffff;
	--rs-color-background-elevation-overlay: #ffffff;
	--rs-color-black: #323232;
	--rs-color-white: #ffffff;
	--rs-color-background-elevated: #ffffff;
	--rs-color-background-base: #ffffff;
	--rs-color-on-background-neutral: #000;
	--rs-color-on-background-primary: #fff;
	--rs-color-on-background-critical: #fff;
	--rs-color-on-background-positive: #fff;
	--rs-color-rgb-background-neutral: 224, 224, 224;
	--rs-color-rgb-background-neutral-faded: 249, 249, 249;
	--rs-color-rgb-background-neutral-highlighted: 202, 202, 202;
	--rs-color-rgb-background-disabled: 249, 249, 249;
	--rs-color-rgb-background-disabled-faded: 250, 250, 250;
	--rs-color-rgb-background-primary: 89, 43, 134;
	--rs-color-rgb-background-primary-highlighted: 137, 87, 182;
	--rs-color-rgb-background-primary-faded: 248, 242, 254;
	--rs-color-rgb-background-positive: 48, 164, 108;
	--rs-color-rgb-background-positive-faded: 214, 237, 226;
	--rs-color-rgb-background-positive-highlighted: 89, 182, 137;
	--rs-color-rgb-background-critical: 240, 61, 61;
	--rs-color-rgb-background-critical-faded: 252, 216, 216;
	--rs-color-rgb-background-critical-highlighted: 255, 116, 104;
	--rs-color-rgb-background-page: 255, 255, 255;
	--rs-color-rgb-background-page-faded: 244, 244, 244;
	--rs-color-rgb-background-elevation-base: 255, 255, 255;
	--rs-color-rgb-background-elevation-raised: 255, 255, 255;
	--rs-color-rgb-background-elevation-overlay: 255, 255, 255;
	--rs-color-rgb-black: 50, 50, 50;
	--rs-color-rgb-white: 255, 255, 255;
	--rs-color-rgb-background-elevated: 255, 255, 255;
	--rs-color-rgb-background-base: 255, 255, 255;
}

[data-rs-theme='fiona'][data-rs-color-mode='dark'] {
	--rs-color-foreground-neutral: #dddddd;
	--rs-color-foreground-neutral-faded: #676767;
	--rs-color-foreground-disabled: #292929;
	--rs-color-foreground-primary: #8957b6;
	--rs-color-foreground-positive: #76a849;
	--rs-color-foreground-critical: #de7064;
	--rs-color-background-neutral: #111111;
	--rs-color-background-neutral-faded: #060606;
	--rs-color-background-neutral-highlighted: #353535;
	--rs-color-background-disabled: #060606;
	--rs-color-background-disabled-faded: #050505;
	--rs-color-background-primary: #a7d479;
	--rs-color-background-primary-highlighted: #76a849;
	--rs-color-background-primary-faded: #592b86;
	--rs-color-background-positive: #a7d479;
	--rs-color-background-positive-faded: #290012;
	--rs-color-background-positive-highlighted: #76a849;
	--rs-color-background-critical: #fdd9d1;
	--rs-color-background-critical-faded: #b60015;
	--rs-color-background-critical-highlighted: #fdd9d1;
	--rs-color-border-neutral: #616161;
	--rs-color-border-neutral-faded: #1f1f1f;
	--rs-color-border-disabled: #131313;
	--rs-color-border-primary: #fcf9ff;
	--rs-color-border-primary-faded: #211a18;
	--rs-color-border-positive: #16f17e;
	--rs-color-border-positive-faded: #2f061c;
	--rs-color-border-critical: #de7064;
	--rs-color-border-critical-faded: #032727;
	--rs-color-background-page: #000000;
	--rs-color-background-page-faded: #1f1f1f;
	--rs-color-background-elevation-base: #000000;
	--rs-color-background-elevation-raised: #000000;
	--rs-color-background-elevation-overlay: #000000;
	--rs-color-black: #dddddd;
	--rs-color-white: #000000;
	--rs-color-background-elevated: #000000;
	--rs-color-background-base: #000000;
	--rs-color-on-background-neutral: #fff;
	--rs-color-on-background-primary: #000;
	--rs-color-on-background-critical: #000;
	--rs-color-on-background-positive: #000;
	--rs-color-rgb-background-neutral: 17, 17, 17;
	--rs-color-rgb-background-neutral-faded: 6, 6, 6;
	--rs-color-rgb-background-neutral-highlighted: 53, 53, 53;
	--rs-color-rgb-background-disabled: 6, 6, 6;
	--rs-color-rgb-background-disabled-faded: 5, 5, 5;
	--rs-color-rgb-background-primary: 167, 212, 121;
	--rs-color-rgb-background-primary-highlighted: 118, 168, 73;
	--rs-color-rgb-background-primary-faded: 89, 43, 134;
	--rs-color-rgb-background-positive: 167, 212, 121;
	--rs-color-rgb-background-positive-faded: 41, 0, 18;
	--rs-color-rgb-background-positive-highlighted: 118, 168, 73;
	--rs-color-rgb-background-critical: 253, 217, 209;
	--rs-color-rgb-background-critical-faded: 182, 0, 21;
	--rs-color-rgb-background-critical-highlighted: 253, 217, 209;
	--rs-color-rgb-background-page: 0, 0, 0;
	--rs-color-rgb-background-page-faded: 31, 31, 31;
	--rs-color-rgb-background-elevation-base: 0, 0, 0;
	--rs-color-rgb-background-elevation-raised: 0, 0, 0;
	--rs-color-rgb-background-elevation-overlay: 0, 0, 0;
	--rs-color-rgb-black: 221, 221, 221;
	--rs-color-rgb-white: 0, 0, 0;
	--rs-color-rgb-background-elevated: 0, 0, 0;
	--rs-color-rgb-background-base: 0, 0, 0;
}
